import React, { useEffect, useRef } from "react";

const scaleCanvas = (canvas, context, width, height) => {
  const devicePixelRatio = window.devicePixelRatio || 1;
  const backingStoreRatio =
    context.webkitBackingStorePixelRatio ||
    context.mozBackingStorePixelRatio ||
    context.msBackingStorePixelRatio ||
    context.oBackingStorePixelRatio ||
    context.backingStorePixelRatio ||
    1;

  const ratio = devicePixelRatio / backingStoreRatio;
  if (devicePixelRatio !== backingStoreRatio) {
    canvas.width = width * ratio;
    canvas.height = height * ratio;

    canvas.style.width = width + "px";
    canvas.style.height = height + "px";
  } else {
    canvas.width = width;
    canvas.height = height;
    canvas.style.width = "";
    canvas.style.height = "";
  }

  context.scale(ratio, ratio);
};

const Logo = () => {
  const ref = useRef();
  const canvasHeight = 25;
  const canvasWidth = 350;

  useEffect(() => {
    const canvas = ref.current;
    const context = canvas.getContext("2d");
    scaleCanvas(canvas, context, canvasWidth, canvasHeight);
    const lineThickness = 1;
    const sideHeight = 15;
    const horizontalDuration = 50;
    const verticalDuration = 10;

    let requestId;
    let frame = 0;
    const render = () => {
      context.fillStyle = "#6b7280";

      context.fillRect(
        canvasWidth / 2,
        canvasHeight - lineThickness,
        (canvasWidth / 2) * Math.min(1, frame / horizontalDuration),
        lineThickness
      );

      context.fillRect(
        (canvasWidth / 2) * Math.max(0, 1 - frame / horizontalDuration),
        canvasHeight - lineThickness,
        (canvasWidth / 2) * Math.min(1, frame / horizontalDuration),
        lineThickness
      );

      if (frame > horizontalDuration) {
        const relative = frame - horizontalDuration;
        context.fillRect(
          0,
          canvasHeight -
            lineThickness -
            sideHeight * Math.min(1, relative / verticalDuration),
          lineThickness,
          Math.max(0, (relative / verticalDuration) * sideHeight)
        );

        context.fillRect(
          canvasWidth - lineThickness,
          canvasHeight -
            lineThickness -
            sideHeight * Math.min(1, relative / verticalDuration),
          lineThickness,
          Math.max(0, (relative / verticalDuration) * sideHeight)
        );
      }

      frame++;
      requestId = requestAnimationFrame(render);
    };

    render();

    return () => {
      cancelAnimationFrame(requestId);
    };
  });

  return (
    <canvas
      ref={ref}
      height={canvasHeight}
      width={canvasWidth}
      className="mb-8"
    />
  );
};

const Heading = ({
  title,
  subtitle,
  body,
  inline,
}: {
  title: string;
  subtitle: string;
  body: string;
  inline?: boolean;
}) => (
  <div>
    <h2 className="heading text-4xl tracking-wide">
      {inline ? (
        <>
          <span className="font-bold">{title}</span>{" "}
          <span className="font-extralight">{subtitle}</span>
        </>
      ) : (
        <>
          <div className="font-bold">{title}</div>
          <div className="font-extralight">{subtitle}</div>
        </>
      )}
    </h2>
    <p className="text-2xl pt-2">{body}</p>
  </div>
);

const LargeSection = ({
  title,
  subtitle,
  body,
}: {
  title: string;
  subtitle: string;
  body: string;
}) => (
  <div>
    <h3 className="heading text-3xl tracking-wide">
      <div className="font-bold">{title}</div>
      <div className="font-extralight">{subtitle}</div>
    </h3>
    <p className="text-lg py-3">{body}</p>
  </div>
);

const SmallSection = ({
  title,
  subtitle,
  body,
}: {
  title: string;
  subtitle: string;
  body: string;
}) => (
  <div>
    <h3 className="heading text-2xl tracking-wide pt-2">
      <div className="font-bold">{title}</div>{" "}
      <div className="font-extralight">{subtitle}</div>
    </h3>
    <p className="text-lg py-2">{body}</p>
  </div>
);

const IndexPage = () => (
  <>
    <div className="bg-gray-50">
      <div className="flex flex-col items-center justify-center text-center w-screen h-screen">
        <Logo />
        <h1
          className="heading text-5xl text-gray-800"
          style={{ letterSpacing: "0.2rem" }}
        >
          <span className="font-bold">BLANK SPACE</span>{" "}
          <span className="font-extralight">LABS</span>
        </h1>
      </div>
      <div className="flex flex-col justify-center max-w-screen-lg mx-auto text-gray-800 min-h-screen px-12 py-6">
        <Heading
          title="YOUR PARTNERS IN"
          subtitle="ENGINEERING EXCELLENCE"
          body={
            <>
              <p className="pt-4">
                Our mission is to elevate your engineering team. From leadership
                coaching to strategic decision-making to technical
                implementation, we partner with you to do great things.
              </p>
              <p className="pt-4">
                With over a decade of engineering and management experience at
                top technology companies, we're excited to accelerate your
                team's growth and impact.
              </p>
            </>
          }
        />
      </div>
      <div className="flex flex-col justify-center max-w-screen-lg mx-auto text-gray-800 min-h-screen px-12 py-6">
        <Heading
          title="WHAT"
          subtitle="WE DO"
          body="We tailor our services to your team's needs. Here are just a few ways we can increase the impact of your engineering organization."
          inline={true}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 pt-12 gap-6">
          <LargeSection
            title="MANAGEMENT"
            subtitle="COACHING"
            body="We offer personalized coaching to accelerate the growth of engineers, tech leads, and managers of all levels. Whether you're an engineer transitioning to a manager role or a seasoned leader taking the next step, we offer one-on-one coaching focused on skill development, live brainstorming & problem-solving, and holding you accountable to goals."
          />
          <LargeSection
            title="TECHNICAL"
            subtitle="CONSULTING"
            body="We offer consulting services on a wide variety of technical problems—backend, frontend, and everything in between. We're available to review code, audit high-level architectures, and contribute to implementations. We pride themselves on quality and speed, whether that's improving your existing codebase or building out a new project from scratch."
          />
        </div>
      </div>
      <div className="flex flex-col justify-center max-w-screen-lg mx-auto text-gray-800 min-h-screen px-12 py-6">
        <Heading
          title="HOW"
          subtitle="WE WORK"
          body="We aim to be up front in what you're going to get when you partner with us. Here's what you can expect."
          inline={true}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 pt-12 gap-6">
          <LargeSection
            title="DIRECTNESS &"
            subtitle="RESPECT"
            body="Beating around the bush never helped anyone. Respectful, but honest communication is core to our working style."
          />
          <LargeSection
            title="CRAFTSMANSHIP &"
            subtitle="DILIGENCE"
            body="Cutting corners only hurts in the long term. Our services focus on quality and getting things done right."
          />
          <LargeSection
            title="COLLABORATION &"
            subtitle="SUPPORT"
            body="Great teams are made of great people. We value close collaboration and supporting you in your goals."
          />
          <LargeSection
            title="GROWTH &"
            subtitle="INITIATIVE"
            body="A little bit of slope makes up for a lot of y-intercept. We focus on going the extra mile to accelerate your team's growth."
          />
        </div>
      </div>
      <div className="flex flex-col justify-center max-w-screen-lg mx-auto text-gray-800 min-h-screen px-12 py-6">
        <div className="md:hidden">
          <a
            className="heading text-6xl text-gray-800"
            href="mailto:hello@blankspacelabs.com"
          >
            <span className="font-bold block">hello@</span>
            <span className="font-extralight">
              <span className="block">blank</span>
              <span className="block">space</span>
              <span className="block">labs</span>
              <span className="block">.com</span>
            </span>
          </a>
        </div>
        <div className="hidden md:block">
          <a
            className="heading text-5xl text-gray-800"
            href="mailto:hello@blankspacelabs.com"
          >
            <span className="font-bold">hello</span>
            <span className="font-extralight">@blankspacelabs.com</span>
          </a>
        </div>
      </div>
    </div>
  </>
);

export default IndexPage;
